@import "/src/variables";

.sec_notFound {
  &__text {
    gap: 30px;
    text-align: center;

    .title-error {
      @media screen and (max-width: $mobile) {
        margin-top: 40px;
      }
    }
  }
}
