@import url("https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto+Slab:wght@600&display=swap");
@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "./variables";

:root {
  --black-transparent: rgba(27, 27, 28, 0.8);
  --black-grey: #2d2e32;
  --bs-gutter-x: 0rem !important;

  /* -----  ----- */
}

//REACT TRANSITION GROUP -------------------------------------------
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.fade-exit-done {
  opacity: 0;
}

// DIV -------------------------------------------------------------
body {
  background-color: $primary-600;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  color: $truegray-100;

  // CONTAINERS -----------------------------------------------------------

  .container-fluid {
    padding: 0 !important;
  }

  .row {
    --bs-gutter-x: 0rem !important;
  }

  section {
    background-color: $primary-600;
    // padding-top: 75px !important;
  }

  // TEXTO -----------------------------------------------------------
  b {
    font-weight: 700;
  }

  li {
    font-size: inherit;
  }

  h1,
  h2,
  h3 {
    // font-family: "Roboto Slab", serif;
  }

  a {
    text-decoration: none !important;

    &:hover {
      color: $primary-500;
    }
  }

  .visor360 {
    border: none;
    background-color: rgb(0, 0, 0);
    max-width: 100%;
    width: 100%;
    height: 400px;
    // padding-left: 20px;
  }
}

/* =================== MEDIA QUERIES (MEDIUM DEVICES) =================== */
@media screen and (min-width: 1024px) {
  body::-webkit-scrollbar {
    width: 8px;
    background: $primary-600;
    border: 3px black;
  }

  body::-webkit-scrollbar-thumb {
    background: $primary-700;
    border-radius: 50px;
  }

  body::-webkit-scrollbar-thumb:hover {
    background: $secondary-300;
  }
}

/* =================== MEDIA QUERIES (SMALL DEVICES) =================== */
@media screen and (max-width: 600px) {
  body {
    font-size: 16px;
  }

  .car-item-dist {
    height: 27vh;
  }

  .car-item-dist-low {
    height: 27vh;
  }
}
