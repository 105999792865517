@import "/src/variables";

.sec_desarrollos-nav {
  .nav_desarrollos {
    background-color: $primary-600;
  }

  .des-item {
    margin: 0px 10px 0px 10px;

    &:hover {
      color: $primary-600 !important;
    }
  }

  ._link-nav {
    color: white;
    max-width: 50%;
    margin: 10px auto;
    text-align: center;

    &:hover {
      color: $secondary-300;
    }
  }

  .active {
    color: $secondary-300;
  }
}

/* =================== MEDIA QUERIES (MEDIUM DEVICES) =================== */
@media screen and (min-width: 1024px) {
  .nav_desarrollos {
    padding-right: 90px;
    padding-left: 95px;
    margin-bottom: 10px;
    // MARKER ----------------------------------------------------
    #marker-desarrollos {
      display: none;
      position: absolute;
      left: 115px;
      bottom: 10px;
      height: 4px;
      width: 30px;

      background-color: $secondary-300;
      transform: translateX(-50%);
      transition: 0.5s ease;
      border-radius: 4px;
      z-index: 2;
    }
  }
}

@media screen and (max-width: 600px) {
  .nav_desarrollos {
    padding-left: 10px;
  }

  .brand-img {
    width: 96px !important;
    height: 40px !important;
  }

  .bg-dark {
    min-height: 2em;
  }
}
