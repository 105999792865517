@import "/src/variables";

.row_portada {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  max-height: 85vh;

  @media screen and (max-width: $mobile) {
    max-height: 90vh;
  }

  .h1_prensa {
    position: absolute;
    width: auto;
    font-weight: $weight-light;
    letter-spacing: 3px;
  }
}
