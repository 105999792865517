@import "/src/variables";

.sec_detalle {
  .row_img-ext {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    max-height: 85vh;
    overflow: hidden;

    @media screen and (max-width: $mobile) {
      max-height: 90vh;
    }

    .img_ext {
      width: 100%;
      opacity: 0.65;
    }

    .div_text-img {
      position: absolute;
      width: auto;

      .h1_title {
        font-family: "Lato", sans-serif;
        margin: 0;
        font-size: 74px;
        font-weight: bold;
        line-height: 0.9 !important;
      }

      .h6_dato {
        margin: 0;
        font-size: 24px;
      }
    }
  }

  .car {
    .car-item {
      background-color: black !important;
      max-height: 85vh;

      @media screen and (max-width: $mobile) {
        max-height: 90vh;
      }
    }

    .car-img-hero {
      opacity: 0.65 !important;
      width: 100%;
    }

    .car-item-caption {
      display: flex;
      justify-content: center;
      position: absolute;
      top: 0;
      bottom: 0;

      .car-item-caption__container {
        text-align: left;

        .h1_title {
          font-family: "Lato", sans-serif;
          margin: 0;
          font-size: 74px;
          font-weight: bold;
          line-height: 0.9 !important;
        }

        .h6_dato {
          margin: 0;
          font-size: 24px;
        }
      }

      @media screen and (max-width: $mobile) {
        top: 0%;

        .car-item-caption__container {
          .h1_title {
            font-size: 44px;
          }

          .h6_dato {
            font-size: 20px;
          }
        }
      }
    }

    .carousel-control-next-icon {
      @media screen and (min-width: $mobile) {
        top: 70%;
      }
    }
  }

  .row_images {
    .col_carousel {
      padding-bottom: 50px;
    }
  }

  .row_desc {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .h2_title {
      padding-top: 70px;
      width: auto;
      font-weight: $weight-bold;
    }

    .h3_state {
      width: auto;
      font-size: 22px;
      opacity: 0.7;
    }

    .p_desc {
      width: 40%;
      text-align: center;

      @media screen and (max-width: $mobile) {
        width: 80%;
        text-align: left;
      }
    }

    .row_downloads {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
      padding-bottom: 70px;

      .a_broch {
        width: auto;
        color: white;
        cursor: pointer;

        &:hover {
          color: white;
        }
      }

      .a_planos {
        width: auto;
        color: white;
        cursor: pointer;

        &:hover {
          color: white;
        }
      }

      .a_consulta {
        width: auto;
        color: white;
        margin-bottom: 4px;
        padding-left: 10px;
        cursor: pointer;

        &:hover {
          color: white;
        }
      }
    }
  }

  .row_similares_proyectos {
    margin-top: 100px;

    .row_similares {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $secondary-600;

      .h1_similares {
        width: auto;
        padding: 10px;
        margin: 0px;
        font-size: 20px;
      }
    }
  }
}
