@import "/src/variables";

.row_gestion {
  background-color: $primary-600;
  text-align: center;
  padding: 5px;
  padding-top: 7rem;
  padding-bottom: 7rem;
  font-size: 20px;

  h3 {
    font-weight: $weight-bold;
  }

  @media screen and (max-width: $mobile) {
    text-align: left;
    font-size: 15px;
  }
}
