@import "/src/variables";

.link_detalle {
  color: white;

  &:hover {
    color: $secondary-300;
  }

  .row_elementoDes {
    cursor: pointer;

    .col_contenedor-img {
      position: relative;
      background-color: white;
      -webkit-transition: background-color 300ms linear;
      -ms-transition: background-color 300ms linear;
      transition: background-color 300ms linear;

      &:hover {
        background-color: $primary-600;
        -webkit-transition: background-color 300ms linear;
        -ms-transition: background-color 300ms linear;
        transition: background-color 300ms linear;

        .div_dat-elemento-1 {
          opacity: 1;
        }
      }

      .img_elemento {
        display: block;
        width: 100%;
        mix-blend-mode: multiply;
        opacity: 1;
        filter: grayscale(0%);
        transition: 0.3s ease-in-out;

        &:hover {
          filter: grayscale(100%);
        }
      }

      .div_dat-elemento-1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;

        color: white;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        pointer-events: none;

        .div_dat-elemento-2 {
          align-items: center;

          .h1_dat-elemento {
            margin: 0;
            margin-bottom: 10px;
            // font-size: 74px;
            font-size: 64px;
            font-weight: bold;
            line-height: 0.9;
          }

          .h6_dat-elemento {
            margin: 0;
            font-size: 24px;
          }
        }
      }
    }
  }
}

/* =================== MEDIA QUERIES (SMALL DEVICES) =================== */
@media screen and (max-width: 600px) {
  .link_detalle {
    .col_dat-elemento {
      .h1_dat-elemento {
        font-size: 44px !important;
      }

      .h6_dat-elemento {
        font-size: 12px !important;
      }
    }
  }
}
