@import "/src/variables";

.navbar-dark {
  // NAVBAR BÁSICO -------------------------------------------------
  &.nav_header {
    background-color: $primary-600;

    .brand-img {
      height: 70px;
      margin-right: 80px;
    }

    .searchform {
      width: 330px;
      margin-left: 5px !important;
    }

    .searchbar {
      font-size: 0.875rem /* 14px */;
      line-height: 1.25rem /* 20px */;
      padding: 10px;
      margin-top: 10px;
      margin-bottom: 10px;

      height: 35px;

      border-radius: 17px !important;

      outline: 2px solid transparent;
      outline-offset: 2px;

      border-style: none;
    }

    ._link-nav {
      color: white;
      max-width: 50%;
      margin: 10px auto;
      text-align: center;

      &:hover {
        color: $secondary-300;
      }
    }
  }

  // LINKS NAVS -----------------------------------------------------
  .nav-item-p {
    margin-right: 10px;
    vertical-align: middle;
  }
}

/* =================== MEDIA QUERIES (MEDIUM DEVICES) =================== */
@media screen and (min-width: 1024px) {
  .nav_header {
    .brand-img {
      margin-left: 100px;
    }

    .contacto-p {
      margin-right: 100px;
    }

    // MARKER ----------------------------------------------------
    #marker-header {
      position: absolute;
      right: 492px;
      bottom: 34px;
      height: 2px;
      width: 21px;

      background-color: $secondary-300;
      transform: translateX(-50%);
      transition: 0.5s ease;
      border-radius: 4px;
      z-index: -1;
    }
  }
}

@media screen and (max-width: 600px) {
  .bg-dark {
    min-height: 2em;
  }

  .brand-img {
    width: 96px !important;
    height: 40px !important;
    padding-left: 10px;
  }

  .brand-img-2 {
    width: 151px;
    height: 40px;
  }
}

/* =================== MEDIA QUERIES (MEDIUM DEVICES) =================== */
@media screen and (max-width: 600px) {
  .nav_header {
    .nav_contenedor {
      display: flex;
      align-items: center;
      justify-content: center;
      .nav-item-p {
        width: auto;

        ._link-nav {
          width: auto;

          &:hover {
            color: $secondary-300;
          }
        }
      }
    }
  }
}
