//================= COLORS =================
// PRIMARY
$primary-400: #004751;
$primary-500: #044454;
$primary-600: #002d33; //Background
$primary-700: #002126;

// SECONDARY
$secondary-300: #17a64c;
$secondary-600: #106734;

// TRUEGRAY
$truegray-100: #fefffe;
$truegray-400: #524e50;
$truegray-500: #3d3b3c;
$truegray-600: #303030; //darkText
$truegray-700: #2d2e32; //blackGray

//================= TYPOGRAPHY =================
$weight-light: 300 !default;
$weight-normal: 400 !default;
$weight-medium: 500 !default;
$weight-semibold: 600 !default;
$weight-bold: 700 !default;

//================= DEVICES =================
$mobile: 600px;
$tablet: 769px;
$desktop: 1024px;
$widescreen: 1152px;
$fullhd: 1920px;
