@import "/src/variables";

.btn-whatsapp {
  // background: $secondary-300;
  width: 45px;
  // color: white;
  border-radius: 10%;

  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;

  bottom: 1.25rem;
  right: 1.3rem;

  z-index: 50;

  &:hover {
    color: white !important;
  }
}

//------------ WhatsApp Button -------------
@media screen and (min-width: 64em) {
  .btn-whatsapp {
    right: 1rem;
    bottom: 3.125rem;
  }
}
