@import "/src/variables";

#contacto {
  background-color: $primary-600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;

  .contact__container {
    margin-top: 6rem;
    margin-bottom: 6rem;

    .contact__options {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
      margin-bottom: 30px;
      // margin-right: 10px;

      .contact__option {
        background: $secondary-300;
        width: 95%;
        padding: 1.2rem;
        border-radius: 1.2rem;
        text-align: center;
        border: 1px solid transparent;
        transition: var(--transition);

        &:hover {
          background: transparent;
          border-color: $truegray-100;
        }
      }
    }
  }
}

.col-contacto {
  align-items: center;
  justify-content: center;
}

.img-360-container {
  background-color: $secondary-300;
}

.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
}

/* =================== FORM =================== */
form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin-left: 30px;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 2px solid var(--color-primary-variant);
  resize: none;
  color: $secondary-600;
}

/* =================== MEDIA QUERIES (MEDIUM DEVICES) =================== */
@media screen and (max-width: 1024px) {
  //   .container.contact__container {
  //     grid-template-columns: 1fr;
  //     gap: 2rem;
  //   }
}

/* =================== MEDIA QUERIES (SMALL DEVICES) =================== */
@media screen and (max-width: 600px) {
  .container.contact__container {
    width: var(--container-width-sm);
  }

  form {
    margin-left: 0px;
  }
}
