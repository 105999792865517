@import "/src/variables";

.prof {
  position: relative;
  display: flex;
  cursor: pointer;

  &__img {
    overflow: hidden;

    img {
      filter: grayscale(100%);
      transition: all 0.3s ease-out;

      &:hover {
        transform: scale(1.1);
        filter: grayscale(0%);
        opacity: 0.8;
        transition: all 0.3s ease-out;
      }
    }
  }

  &__info {
    position: absolute;
    flex-direction: column;
    background-color: $secondary-600;
    height: 100%;
    transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
    padding: 15px;
    overflow: hidden;

    &.--left {
      left: 100%;
      align-items: left;
      text-align: left;
    }
    &.--right {
      right: 100%;
      align-items: right;
      text-align: right;
    }

    .h1_name {
      font-weight: $weight-bold;
    }

    .h1_lastname {
      font-weight: $weight-bold;
    }

    @media (min-width: 1050px) {
      width: 200%;
    }

    @media (max-width: 600px) {
      width: 100%;
    }

    &.--hidden {
      z-index: 10;
      visibility: hidden;
      opacity: 0;
    }

    &.--active {
      z-index: 10;
      visibility: visible;
      opacity: 1;
    }
  }
}
