@import "/src/variables";

.sec-noticia {
  background-color: white;

  .notice {
    color: $truegray-600;
    padding-top: 80px;
    padding-bottom: 70px;

    &__title {
      font-size: 28px;
      text-align: center;
      padding-bottom: 40px;
      font-weight: bold;
    }

    &__subtitle {
      padding-bottom: 40px;

      h2 {
        font-size: 26px;
        font-weight: bold;
      }
    }

    &__content {
      font-size: 16px;
      font-weight: 500;

      .parrafos {
        p {
          padding-bottom: 10px;
        }

        &--fuente {
          padding-top: 30px;
        }
      }

      .img-container {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }
}
