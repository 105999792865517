@import "/src/variables";

.sec-desarrollos {
  .col-desarrollos {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $secondary-300;

    .h2-titulo {
      margin: 10px;
      cursor: pointer;
      color: white;
      font-weight: $weight-bold;

      &:hover {
        color: white;
      }
    }
  }

  .col-espacio {
    background-color: $secondary-600;
  }
}
