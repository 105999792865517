@import "/src/variables";

.datos-sec {
  background-color: $truegray-100;
  color: $primary-400;
  padding-top: 70px;
  padding-bottom: 70px;

  .col-elemento {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media screen and (min-width: $desktop) {
      margin-left: 50px;
      margin-right: 50px;
    }

    @media screen and (max-width: $mobile) {
      margin-bottom: 40px;
      margin-top: 40px;
    }

    .svg-elementos {
      width: 90px;
      height: 100px;
    }

    .texto-elementos {
      text-align: center;
      margin-top: 25px;
    }
  }
}
