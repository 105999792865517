@import "/src/variables";

.row_container-noticia {
  padding-top: 150px !important;
  // padding-bottom: 95px !important;
  background-color: white;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;

  .row_columns {
    padding-top: 10px;
    padding-bottom: 10px;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: gray;
    }
  }

  .col_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    color: black;
    cursor: pointer;
  }

  .col_img {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media (min-width: 1050px) {
      padding-left: 20px;
    }

    .img_noticia {
      width: 100%;
    }
  }

  .div_separador_grey {
    margin-top: 150px;
    width: 600px;
    height: 1px;
    background-color: grey;
  }

  .div_separador {
    margin-top: 150px;
  }
}

@media screen and (max-width: 600px) {
  .row_container-noticia {
    padding-top: 90px !important;

    .col_text {
      padding: 10px;
    }

    .col_img {
      .img_noticia {
        width: 100%;
      }
    }
  }
}
