@import "/src/variables";

.car-item {
  background-color: black !important;
  // max-height: 85vh;

  @media screen and(max-width: $mobile) {
    // max-height: 90vh;
  }

  .car-img-hero {
    opacity: 0.65 !important;
    width: 100%;
  }

  .car-item-caption {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;

    &__container {
      max-width: 500px;
    }

    h3 {
      font-weight: $weight-light;
      letter-spacing: 3px;
    }
  }
}

.carousel-control-next-icon {
  @media screen and (max-width: $mobile) {
    top: 70% !important;
  }
}
