@import "/src/variables";

.sec-footer {
  .derechos {
    margin-bottom: 0px !important;
  }

  .col-img-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 30px;

    .div_img-footer {
      @media (min-width: 1050px) {
        width: 40%;
      }

      @media (max-width: 600px) {
        width: 90%;
      }
    }
  }

  .ig-icon {
    margin-left: 5px;
  }
}

/* =================== MEDIA QUERIES (SMALL DEVICES) =================== */
@media screen and (max-width: 600px) {
  .sec-footer {
    .col-img-footer {
      margin-bottom: 20px;
    }
  }
}
